import React, { useState, useEffect, useRef } from "react"
import PhoneInput from 'react-phone-input-2'
import { acceptOnlyNumbers } from "../../../service/helper"

import LinkButton from "../button/linkButton"

const MobileVerifyViaOTP = (props) => {

    const { form, setForm, otp, setOTP, verifyOpen, mobileValidator, otpValidator, onVerify, otpLoading, onResendOTP } = props

    return (
        <div className="col-lg-12 col-md-12">
            <div className="row otp_row">
                
                {verifyOpen &&
                    <div className="col-md-4 mb-2">
                        <div className={`form-group -animated ${otp ? '-active' : ''}`}>
                            <label>Enter OTP <span className="mandatory">*</span></label>
                            <input className="form-control" type="text" id="OTP" value={otp} onChange={(e) => setOTP(acceptOnlyNumbers(e.target.value))}/>
                            {/* <i class="icon success"></i> */}
                            {otpValidator.current.message('OTP', otp, 'required')}
                        </div>
                        <LinkButton title={`Resend OTP`} resend onBtnClick={onResendOTP} loading={otpLoading} />
                    </div>
                }
            </div>
        </div>  
    )
}

export default MobileVerifyViaOTP